#certification-header {
  background-image: url('../images/new_site/backgrounds/radial-circuits.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 738px;
  height: 336px;
}

#certification-explanation-container {
  @extend .m-auto;

  max-width: 668px;
  margin-top: 0px;

  .card-container {
    width: 125%;
    transform: translate(-10%);
    padding-left: initial;
    padding-right: initial;
  }

  .card-header {
    margin-bottom: 0px;
  }

  .card-body {
    @extend .pt-0;
  }
}