#home-header {
  margin-top: 6rem !important;
  position: relative;

  &.header {
    &:before {
      @keyframes backgroundFadeIn {
        0%   { opacity: 0; background-size: 50%; }
        100% { opacity: 1; background-size: 85%; }
      }

      @include media-breakpoint-only(xs) {
        background-size: 85%;
        width: 200%;
        left: -50%;
      }

      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      animation: backgroundFadeIn 0.5s linear 0.5s forwards;
    }
  }

  &.header-hidden {
    &:before {
      display: none;
      // @keyframes backgroundFadeOut {
      //   0%   { opacity: 1; }
      //   100% { opacity: 0; }
      // }

      // animation: backgroundFadeOut 0.5s linear 0.5s forwards;
    }
  }

  .mx-sm-5 {
    #cta-primary-header {
      @extend %animation-element-container;
      animation: slideAndFadeIn 0.3s linear 1s forwards;
    }
  
    #cta-secondary-header {
      @extend %animation-element-container;
      animation: slideAndFadeIn 0.3s linear 1.3s forwards;
    }

    #cta-request-trial-button a, #cta-watch-demo-button button {
      @extend %animation-element-container;
      animation: fadeIn 0.3s linear 3s forwards;

      &.white.purple-border {
        box-sizing: content-box;
      }
    }

    #cta-watch-demo-button button {
      // If box-sizing is set to border-box then the size of the font-awesome icon
      // is not accounted for when applying margins/padding
      box-sizing: content-box;

      // it's a "purple button" only for the sake of hover animation
      background-color: white !important; 
      display: flex;
      align-items: center;
      justify-content: center;

      span, i {
        color: rgba(119, 91, 194, 1.0);
        line-height: 26px;

        &.material-icons {
          font-size: 20px;
          line-height: 20px;
          margin-left: 8px;
        }
      }

      // With box-sizing set to content-box, the left/right padding is too much
      // Need to override the bootstrap styling here
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    #cta-client-logos {
      .client-logo {
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            animation-delay: 1.3s + ($i * (1s / 4));
          }
        }

        @keyframes clientLogoSlideAndFadeIn {
          0%   { opacity: 0; transform: translateY(15px); }
          100% { opacity: 0.5; transform: translateY(0px); }
        }

        @extend %animation-element-container;
        max-height: 65px;
        max-width: 300px;
        animation: clientLogoSlideAndFadeIn 0.3s linear forwards;
      }
    }

    #cta-lower-text {
      @extend %animation-element-container;
      animation: slideAndFadeIn 0.3s linear 2.7s forwards;
    }
  }
}

@include media-breakpoint-only(xs) {
  #intro {
    background-size: auto;
  }
}

.claim {
  @extend .col-12;
  @extend .col-md;

  .why-image {
    height: 200px;
  }

  .claim-container {
    .claim-item {
      @extend .mt-3;

      .claim-text {
        @extend .ml-1;

        .secondary {
          @extend .mr-4;
        }
      }
    }
  }
}

#carousel-wrapper {
  #carousel {
    background: url('../images/new_site/backgrounds/cloud.png') no-repeat center;
    background-size: 100%;
    @include media-breakpoint-only(xs) {
      background-size: auto;
    }

    #features-carousel {
      .list-group-item {
        background: none;
        border: none;
        cursor: pointer;

        svg {
          visibility: hidden;
        }

        .active {
          p {
            font-weight: bolder;
          }

          svg {
            visibility: visible;
          }
        }
      }
    
      .carousel-item img {
        border: 10px solid #FFFFFF;
        box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
        border-radius: 3px;
        height: 400px;
      }
    }
  }
}

#mobile-features-carousel {
  nav {
    padding: 0;

    .navbar-nav {
      display: flex;
      flex-flow: row nowrap;
      overflow-x: scroll;
      align-items: center;

      .nav-item {
        @extend .mx-3;
        border-bottom: solid #FFFFFF 4px;
        opacity: 0.5;
        padding: 5px;
        white-space: nowrap;

        &.active {
          border-bottom: solid $purple 4px;
          opacity: 1;

          a {
            color: black;
          }
        }
      }
    }
  }

  .mobile-swipe-indicator {
    color: #C3C3C3
  }
}

.category {
  @extend .px-0;
  @extend .py-2;

  height: 52px;
  width: 105px;

  &.active {
    box-shadow: 0px 5px 15px rgba(51, 51, 51, 0.08);
  }

  p {
    @extend .mt-1;
    @extend .mb-0;
    @extend .h-100;
  }
}

#case-studies {
  margin: 0 auto;
  max-width: 1600px;

  > h3 {
    text-align: center;
  }

  .case-study-card {
    flex-basis: 32%;
  }
}

#license-that-fits {
  .circle {
    border-radius: 50%;
    box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
    height: 120px;
    width: 120px;
  
    display: flex;
    justify-content: center;
  
    img {
      align-self: center;
      max-width: 72px;
      max-height: 72px;
    }
  }

  .row.justify-content-center {
    @media (min-width: 576px) {
      button {
        height: 50px;
        width: 185px;
      }
    }
  }
}