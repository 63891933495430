#request-container {
  #request-header {
    margin-bottom: 24px;

    img {
      width: 198px;
      height: 40px;
    }
  }

  .form-container {
    width: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 40px;
    box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 3px;

    a {
      color: #0052CC;
    }

    h3 {
      height: 20px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #172B4D;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin-bottom: 24px;
    }

    form {
      padding: 0;
      margin: 0 !important;
      height: auto;
      width: 320px;
  
      small {
        z-index: 100;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        padding-left: 8px;
      }
  
      &#registration-form, &#login-form, &#password-form, &#send_password_form {
        width: 100%;

        .form-group {
          height: auto;
          margin-bottom: 16px !important;

          #submit-button:disabled {
            opacity: 0.5;
    
            &:hover {
              box-shadow: none;
              cursor: not-allowed;
            }
          }

          .input-group-inner {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;

            input {
              flex-grow: 1;
            }
          }

          input {
            width: 320px;
            height: 40px;
            background-color: #FAFBFC;
            border: 1px solid #DFE1E6;
            border-radius: 3px;
            padding: 10px 8px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 14px;
            line-height: 20px;
            color: var(--aui-body-text);
            outline: none;
            box-shadow: none;

            &::placeholder {
              color: #A5ADBA;
            }
          }

          .btn.btn-danger {
            outline: none;
            box-shadow: none;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -3px;
            width: 40px;
            height: 100%;
            background-color: #FAFBFC;
            border-top: 1px solid #DFE1E6;
            border-right: 1px solid #DFE1E6;
            border-bottom: 1px solid #DFE1E6;
            border-left: none;
            color: black;

            i.material-icons {
              color: black;
              font-size: 18px;
              line-height: 18px;
            }
          }

          .material-icons.close {
            float: unset;
            text-shadow: unset;
            opacity: 1;
            color: white;
          }
        }

        button.blue, .aui-button-primary {
          --aui-button-default-disabled-bg-color: rgba(9, 30, 66, 0.04);
          --aui-button-default-disabled-text-color: #A5ADBA;
          --aui-button-primary-bg-color: #0052cc;
          --aui-button-primary-text-color: #fff;
          --aui-button-primary-hover-bg-color: #0065ff;
          --aui-button-primary-active-bg-color: #0052cc;
          --aui-button-primary-active-text-color: #fff;
          --aui-button-primary-disabled-bg-color: var(--aui-button-default-disabled-bg-color);
          --aui-button-primary-disabled-text-color: var(--aui-button-default-disabled-text-color);

          --aui-btn-bg: var(--aui-button-primary-bg-color);
          --aui-btn-text: var(--aui-button-primary-text-color);

          height: 32px;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 24px;

          background-color: var(--aui-btn-bg);
          color: var(--aui-btn-text);

          &:focus, &:hover {
            --aui-btn-bg: var(--aui-button-primary-hover-bg-color);
            --aui-btn-text: var(--aui-button-primary-active-text-color)
          }

          &:active {
            --aui-btn-bg: var(--aui-button-primary-active-bg-color);
            --aui-btn-text: var(--aui-button-primary-active-text-color)
          }

          &.active {
            --aui-btn-bg: var(--aui-button-default-selected-bg-color);
            --aui-btn-text: var(--aui-button-default-selected-text-color)
          }

          &[disabled] {
            --aui-btn-bg: var(--aui-button-primary-disabled-bg-color);
            --aui-btn-text: var(--aui-button-primary-disabled-text-color);
          }
        }

        .footer-links a {
          color: #0052CC;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
      }
    }
  }
}