#licensing-header {
  background-image: url('../images/new_site/backgrounds/radial-circuits.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 738px;
  height: 336px;
}

#licensing-table {
  box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);

  .table-row {
    @extend .px-3;
    @extend .py-2;
    @extend .mx-md-0;

    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.3);

    &.table-header {
      @extend .purple;

      .table-column {
        small, small b {
          color: $faded-white;
        }
      }
    }

    .table-column {
      @extend .d-flex;
      @extend .align-items-center;

      &.no-mobile {
        @extend .d-none;
        @extend .d-md-flex;
      }

      &.col {
        @extend .text-right;
      }

      small {
        @extend .w-100;
      }

      small, small b {
        color: $black;
      }
    }
  }
}