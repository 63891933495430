[data-link] {
  cursor: pointer;
}

.case-study-card {
  @extend .mx-2;

  flex-basis: 30%;
  flex-grow: 1;

  .card-title {
    font-size: 20px;
    min-height: 44px;
  }
}

.other-studies-container {
  width: 100% !important;
  transform: translate(0) !important;
}

.card-container {
  @extend .px-2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .case-study-card {
    @extend .card;
    @extend .m-2;

    box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
    flex-basis: 45%;
    flex-grow: 1;
    min-width: 300px;

    .card-body {
      @extend .mx-0;
      @extend .mx-md-3;

      display: flex;
      flex-direction: column;

      .person {
        @extend .mb-2;

        display: flex;
        min-height: 60px;

        b {
          color: black;
        }
      }

      .link-container {
        align-items: flex-end;
        display: flex;
        flex-grow: 1;

        a {
          font-weight: bold;
        }
      }
    }
  }
}

.case-study-card.testimonial-card {
  flex-basis: 100% !important;
  flex-grow: 3;
  height: 340px;

  @media (max-width: 400px) {
    height: 525px;
  }

  #carousel-wrapper {
    display: flex;
    flex-grow: 1;

    #testimonials-carousel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .carousel-inner {
        box-shadow: none;

        // When the carousel is moving left to right, this is the previously active item
        .carousel-item.active.carousel-item-left {
          transform: translate3d(-100%, 0, 0);
        }

        // When the carousel is moving left to right, this is the item that will be active
        .carousel-item.carousel-item-next.carousel-item-left {
          transform: translate3d(100%, 0, 0);
        }

        // When the carousel is moving right to left, this is the previously active item
        .carousel-item.active.carousel-item-right {
          transform: translate3d(100%, 0, 0);
        }

        // When the carousel is moving right to left, this is the item that will be active
        .carousel-item.carousel-item-prev.carousel-item-right {
          transform: translate3d(-100%, 0, 0);
        }

        .carousel-item-next .active.carousel-item-right {
          transform: none;
        }

        .carousel-item.active, .carousel-item-next, .carousel-item-prev {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          transition: transform 0.6s ease;

          .card-body {
            align-items: center;
            justify-content: space-around;

            h6 {
              text-align: center;
            }

            .card-title {
              display: flex;
              justify-content: center;

              img {
                @extend .mr-3;

                border-radius: 50%;
                height: 75px;
                width: 75px;
              }

              @media (min-width: 576px) {
                max-width: 50%;
              }

              .person-title {
                max-width: none;

                b {
                  display: block;
                }
              }
            }
          }
        }
      }

      .bottom-container {
        @extend .mx-md-3;
        @extend .mb-2;
        @extend .px-md-3;

        display: flex;

        @media (max-width: 900px) {
          align-items: center;
          flex-direction: column;

          .bottom-indicators {
            display: none;
          }
        }


        a {
          font-weight: bold;
        }
      }

      .bottom-indicators {
        position: static;
        width: 45%;

        li {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}

#case-study-container {
  @extend .col-md-7;

  max-width: 900px;

  > * {
    @extend .mx-md-5;
    @extend .px-md-5;
    @extend .my-4;
  }

  .title {
    text-align: center;

    h1 {
      @extend .d-none;
      @extend .d-md-block;
    }

    h3 {
      @extend .d-md-none;
    }

    .testimonial-link {
      i {
        text-align: center !important;
      }
    }
  }

  #company-identity {
    align-items: center;
    display: flex;
    justify-content: center;

    > * {
      @extend .mx-1;
      @extend .mx-md-2;
    }

    .company-logo {
      @extend .d-none;
      @extend .d-md-block;      
    }

    .divider {
      @extend .d-none;
      @extend .d-md-block;

      border: 1px solid $light-gray;
      height: 80px;
    }

    .person {
      align-items: center;
      display: flex;
      justify-content: center;

      small {
        color: $dark-gray;

        b {
          color: black;
        }
      }
    }
  }

  #challenges, #outcomes {
    @extend .my-3;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .icon-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 150px;

      .icon {
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
        display: flex;
        justify-content: center;

        height: 100px;
        width: 100px;

        img {
          height: 50%;
          width: 50%;
        }
      }

      b {
        @extend .mt-2;
        @extend .mx-2;

        text-align: center;
      }
    }
  }

  .quote {
    @extend .mx-md-0;
    @extend .px-md-0;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }

  .central-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 25px;
  }

  .secondary-image {
    display: block;
    padding-bottom: 25px;
    width: 100%;
  }

  .secondary-image-gif {
    display: block;
    margin-left: -75px;
    padding-bottom: 25px;
    width: 120%;
  }

  .secondary-image-small {
    display: block;
    margin-left: 55px;
  }
}

div#video-container {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;

  iframe {
    box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.37);
  }
}

#other-case-studies {
  margin: 0 auto;
  max-width: 1600px;

  > h5 {
    text-align: center;
  }

  .case-study-card {
    flex-basis: 30%;
  }
}
