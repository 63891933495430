#careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  #careers-navbar {
    width: auto;
  }

  .career-section {
    @extend .pb-2;
    max-width: 787px;
    padding-top: 6rem;
    margin-top: -5rem;
    -webkit-background-clip: content-box;
    background-clip: content-box;

    &:first-child {
      margin-top: -5rem;
    }
  }
}