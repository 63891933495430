@import "support_card";

.support-header-image, .academy-header-image {
  i {
    font-size: 88px;
  }
}

.support-chat-body {
  div.d-flex {

    img {
      width: 50px;
    }

    .btn {
      background-color: $purple;
      color: white;
      min-width: 170px;
      text-align: center;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }

    button.btn {
      min-height: 48px;
    }
  }
}