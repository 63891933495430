#network-test-table {
  th:first-child, td:first-child {
    width: 150px;
    text-align: center;

    button {
      width: 120px;
      color: #FFF;
    }

    #ajax-get-button          { color: $purple; }
    #post-button              { background-color: $purple; }
    #post-button:hover        { background-color: darken($purple, 10%); }
    #ajax-post-button         { background-color: $blue; }
    #ajax-post-button:hover   { background-color: darken($blue, 10%); }
    #patch-button             { background-color: $dark-red; }
    #patch-button:hover       { background-color: darken($dark-red, 10%); }
    #ajax-patch-button        { background-color: $dark-blue; }
    #ajax-patch-button:hover  { background-color: darken($dark-blue, 10%); }
    #put-button               { background-color: $green; }
    #put-button:hover         { background-color: darken($green, 10%); }
    #ajax-put-button          { background-color: $yellow; }
    #ajax-put-button:hover    { background-color: darken($yellow, 10%); }
    #delete-button            { background-color: $orange; }
    #delete-button:hover      { background-color: darken($orange, 10%); }
    #ajax-delete-button       { background-color: $red; }
    #ajax-delete-button:hover { background-color: darken($red, 10%); }
    #thumbs-up-button         { pointer-events: none; }
  }

  td:last-child {
    button {
      cursor: none;
      opacity: 1;
      filter: none;

      &.success {
        color: #FFF;
        background-color: $green;
  
        span { color: $dark-green; }
      }
  
      &.failure {
        color: #FFF;
        background-color: lighten($red, 40%);
  
        span { color: $dark-red; }
      }
    }
  }
}

#network-test-failure-explanation {
  padding-top: 35px;
}
