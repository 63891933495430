div#recent-updates {
  div#page-title {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../images/new_site/backgrounds/cloud4.png');

    // Tablet and up!
    &.d-md-none {
      background-size: 60%;
    }

    // Mobile
    &.d-md-none {
      background-size: 100%;
    }

    h1, h3 {
      text-align: center;
    }
  }

  div.row {
    &.update {
      div.tag {
        display: inline-block;
        border-radius: 15px;
        height: 25px;

        small {
          color: white;
        }
      }
    }

    div.day-separator {
      border-top: 2px solid #FAFAFA;
      padding: 2px 15px;
    }
  }
}
