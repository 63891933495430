%image-shadow {
  background: $light-gray;
  border: 10px solid $white;
  box-sizing: border-box;/* Card */
  box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
  border-radius: 3px;
}

%animation-element-container {
  opacity: 0;
  position: relative;
  z-index: 2;
}

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideAndFadeIn {
  0%   { opacity: 0; transform: translateY(15px); }
  100% { opacity: 1; transform: translateY(0px); }
}

button, .btn {
  border-radius: 4px;
  border-color: transparent;
  color: $text-white;
  cursor: pointer;

  &.big {
    @extend .py-2;
    width: 172px;
    line-height: 26px;
    font-size: 16px;

    &.white {
      @extend .px-4;
    }

    &.purple-border {
      border-color: $purple;
    }
  }

  &.white {
    background-color: #ffffff;
    color: $purple;

    &:hover {
      color: $purple;
      box-shadow: 0px 6px 40px rgba($color: $white, $alpha: 0.4);
    }
  }

  &.purple {
    background-color: $purple;
    color: white;

    &:hover {
      box-shadow: 0px 6px 20px rgba($color: $purple, $alpha: 0.5);
    }
  }

  &:hover {
    color: $faded-white;
  }

  &.inactive {
    opacity: 0.5;
  }

  &.orange:hover {
    box-shadow: 0px 6px 20px rgba($color: $orange, $alpha: 0.5);
  }
}

// !important flags override bootstrap default coloring
nav.navbar-expand-lg {
  .nav-link {
    @extend .mx-md-2;
    @extend .mx-xl-3;
    white-space: nowrap;
  }

  .nav-link, .nav-item {
    color: $black !important;

    &.purple {
      color: $faded-white !important;
    }

    i.material-icons {
      padding-left: 0.3rem;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .navbar-toggler {
    border: none;
    color: $black !important;
  }

  .navbar-collapse {
    .show, .collapsing {
      .row {
        flex-flow: column nowrap;
      }

      #buttons {
        flex-flow: row nowrap;
        justify-content: space-between;

        @media (max-width: 993px) {
          width: 100%;
        }
      }

      .dropdown-menu {
        border: none;

        @media (max-width: 993px) {
          padding: 0px;

          .dropdown-item {
            padding: 4px 0px;
          }
        }

        @media (min-width: 992px) {
          border: none;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
        }
      }
    }
  }
}

.hero-call-to-action {

  &.purple {
    background-color: $purple;
    color: white;
  }

  h3 {
    line-height: 61px;
    font-size: 44px;
    text-align: center;
  }

  .subheader-container {
    line-height: 30px;
    font-size: 18px;
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  .hero-call-to-action {
    .subheader-container {
      width: 100%;
    }
  }
}

.scrollspy-navbar {
  background-color: white;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
  overflow-x: auto;
  width: 100%;

  scrollbar-width: none;


  border-bottom: 1px solid #F0F0F0;

  .navbar-nav {
    .nav-item:last-child {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .nav-item {
    @extend .ml-3;
    @extend .mr-5;
    @extend .px-0;
    @extend .mr-4;
  
    border: none;
    border-bottom: 4px solid #FFFFFF;
    opacity: 0.5;
    white-space: nowrap;
    cursor: pointer;
    color: $black;

    font-family: $secondary-font-bold;
    font-style: normal;
    font-weight: normal;
  
    &.active {
      background: none;
      color: $black;
      opacity: 1;
      border-bottom: solid $purple 4px;
    }
  }
}

#frequently-asked-questions {
  .card, .card-header {
    border: none;
  }

  .card-header {
    background-color: inherit;
    cursor: pointer;

    div[aria-expanded="false"] .expanded-icon,
    div[aria-expanded="true"] .collapsed-icon {
      display: none;
    }
  }
}

.pagination {
  > div {
    @extend .px-2;
    border-radius: 50%;
    height: 26px;
    width: 26px;

    &.arrow {
      @extend .mx-0;

      box-shadow: 0px 0px 5px rgba(30, 32, 34, 0.05);
    }

    &.page {
      @extend .text-center;
    }

    &.active {
      @extend .px-0;

      background-color: $purple;
      color: $faded-white;
    }
  }
}

.footer-call-to-action {
  color: $text-white;

  background: radial-gradient(216.11px at 50% 50%, rgba(119, 91, 194, 0.86) 0%, #775BC2 100%);

  .button-container {
    .btn:first-of-type {
      @extend .mx-2;
      @extend .mx-sm-3;
    }
  }

  #company-logos > img {
    opacity: 0.5;
    /* mix-blend-mode: screen; Not working for FF*/
    max-height: 65px;
    max-width: 300px;
  }

  .quote {
    p {
      background: url('../images/new_site/backgrounds/quote.svg') no-repeat center;
      background-size: 100%;
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      max-width: 450px;
    }
  }
}

.footer {
  #footer-logo {
    img {
      height: 60px;
    }
  }
}

#footer > div > div {
  &#footer-logo {
    @extend .col-12; 
    @extend .col-lg;
    @extend .pb-5;
  }

  @extend .col-6;
  @extend .col-sm;
  @extend .py-2;
}

// CSS rules specifically form pages that are rendered with the minimal layout

@include media-breakpoint-up(md) {
  .minimal > div {
    width: 80%;
    margin: auto;
  }
}

@include media-breakpoint-up(lg) {
  .minimal > div {
    margin: auto;

    .form-container {
      width: 430px;
    }
  }
}

.form-container {
  small b {
    color: $black;
  }
}

.testimonial-image {
  max-height: 60px;
  max-width: 60px;
}

.encircled {
  border-radius: 50%;
}

// Stickfill Rules
.sticky-top {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1020;
}

.sticky-top:before,
.sticky-top:after {
    content: '';
    display: table;
}
