.testimonial-group {
  @extend .my-3;

  .testimonial-card {
    @extend .card;
    @extend .m-2;

    box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
    flex-basis: 327px;
    flex-grow: 1;

    .card-body {
      @extend .my-md-2;

      display: flex;
      flex-direction: column;

      .card-title {
        align-items: center;
        display: flex;

        img {
          border-radius: 50%;
          height: 75px;
          width: 75px;
        }

        > div {
          @extend .ml-3;

          h6 {
            margin-bottom: 0px;
          }

          .person-title {
            max-width: 200px;
          }
        }
      }

      .company-logo > img {
        max-height: 35px;
      }
    }
  }
}
