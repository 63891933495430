$black: #333333;
$blue: #0792CB;
$dark-blue: #337ab7;
$dark-gray: #777777;
$faded-white: rgba($color: #ffffff, $alpha: 0.95);
$gray: #C3C3C3;
$green: #83CE3D;
$dark-green: #009900;
$light-gray: #FAFAFA;
$orange: #F28F18;
$purple: #775BC2;
$red: #E51433;
$dark-red: #FF0000;
$text-white: rgba($color: #ffffff, $alpha: 0.95);
$yellow: #E9CB00;
$washed: #FAFAFD;
$white: #FFFFFF;
$graysh-bg: #f4f3ef;
$active-blue: #0747A6;
$primary-blue: #0052CC;
$background-blue: #DEEBFF;
$table-row-hover: #EBECF0;
$table-even-bg: #FAFBFC;
$table-odd-bg: #FFFFFF;

.orange {
  background-color: $orange !important;
  color: $text-white;
}

.blue {
  background-color: $primary-blue;
}

.purple {
  color: $purple;
}
