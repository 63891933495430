#error-container {
  height: 600px;

  @include media-breakpoint-only(xs) {
    height: 400px;
    background-size: 125%;
  }

  .container {
    max-width: 675px;

    h3 {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 600;
      font-size: 36px;
      line-height: 56px;
    }

    a {
      color: $primary-blue;
    }

    a.btn.btn-primary {
      color: white;
    }
  }
}