@mixin font-face($font-family, $url, $style: normal) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('../fonts/#{$url}.eot');
		src: url('../fonts/#{$url}.eot?#iefix') format('embedded-opentype'),
		     url('../fonts/#{$url}.woff') format('woff');
		font-weight: normal;
		font-style: $style;
  }
}

// CeraPro
@include font-face("CeraPro-Regular", "CeraPro-Regular");
@include font-face("CeraPro-Regular", "CeraPro-RegularItalic", italic);
@include font-face("CeraPro-Thin", "CeraPro-Thin");
@include font-face("CeraPro-Thin", "CeraPro-ThinItalic", italic);
@include font-face("CeraPro-Light", "CeraPro-Light");
@include font-face("CeraPro-Light", "CeraPro-LightItalic", italic);
@include font-face("CeraPro-Medium", "CeraPro-Medium");
@include font-face("CeraPro-Medium", "CeraPro-MediumItalic", italic);
@include font-face("CeraPro-Bold", "CeraPro-Bold");
@include font-face("CeraPro-Bold", "CeraPro-BoldItalic", italic);
@include font-face("CeraPro-Black", "CeraPro-Black");
@include font-face("CeraPro-Black", "CeraPro-BlackItalic", italic);

// HarmoniaSans
@include font-face("HarmoniaSansStd-Regular", "38BE75_0_0");
@include font-face("HarmoniaSansStd-Italic", "38BE75_1_0");
@include font-face("HarmoniaSansStd-Bold", "38BE75_2_0");
@include font-face("HarmoniaSansStd-BoldItalic", "38BE75_3_0");
