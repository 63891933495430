#request-container {
    .user-registration {
        width: 480px;
    }
    .form-group {
        @extend .text-left;
        @extend .mb-4;
        height: 52px;
        small {
            padding-left: 15px;
            position: absolute;
            color: #775BC2;
        }
        input {
            &.inputFilled,
            &:focus {
                outline: none;
                padding-top: 1rem;
                padding-bottom: 0rem;
            }
            &.form-check-input {
                border: 1px solid #777777;
                box-sizing: border-box;
                border-radius: 3px;
            }
        }
        &.dropdown {
            height: 80px;
        }
        &.date {
            height: 80px;
            .date-inputs {
                text-align: center;
                select {
                    &.date {
                        &:first-of-type {
                            margin-right: 4px;
                        }
                        width: 49%;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .form-check {
        height: 32px;
        margin-bottom: 0 !important;
        label {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            line-height: 24px;
            font-size: 14px;
            a {
                line-height: 24px;
                font-size: 14px;
            }
        }
        &:last-of-type {
            margin-bottom: 2rem !important;
            @include media-breakpoint-only(xs) {
                margin-bottom: 4rem !important;
            }
        }
        &.max-content-height {
            height: max-content;
        }
        &.no-bottom {
            margin-bottom: 0 !important;
        }
    }
    form.was-validated {
        .form-group {
            input:invalid,
            .invalid {
                border: 1px solid #E51433;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(193, 66, 66, 0.25);
                }
            }
            .invalid-feedback {
                @extend .ml-3;
            }
        }
    }
}