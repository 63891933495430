#support-container {
  .card-deck {
    margin-left: 0;
    margin-right: 0;
    padding: 0 15px;

    @media screen and (max-width: 1140px) {
      max-width: 750px;
      margin: 0 auto;
      display: block;

      .support-card.card {
        width: 100%!important;
        float: unset!important;
        margin: 1.5rem auto;
      }
    }
  }

  @import "../../shared/support.scss";
}