@mixin font-face($font-family, $url, $weight, $style: normal) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('../fonts/#{$url}.eot') format('eot'),
         url('../fonts/#{$url}.woff') format('woff'),
         url('../fonts/#{$url}.woff2') format('woff2'),
         url('../fonts/#{$url}.ttf') format('ttf');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face("Segoe UI", "SegoeUI/segoeui", 400);
@include font-face("Segoe UI Semibold", "SegoeUI/segoeuisb", 700);
@include font-face("Segoe UI Mono", "SegoeUIMono/SegoeUIMono", 400);
