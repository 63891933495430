#password-validation-list, #password-confirmation-validation {
    padding: 0;
    white-space: initial;

    h3 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: white;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    hr {
        margin: 8px 0;
        height: 2px;
        width: 100%;
        background-color: #505F79;
    }

    ul {
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        min-height: unset;
        height: auto;
        margin: 0;
    }
        
    li {
        list-style:none;
        // overflow: visible;
        padding-left: 20px;
        position: relative;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i.material-icons {
            display: none;
            font-size: 10px;
            line-height: 10px;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 4px;
        }

        span {
            font-size: 14px;
            line-height: 20px;
        }

        &:not(.invalid) {
            i.material-icons.check_circle {
                display: block;
                color: #57D9A3;
            }

            span {
                color: #57D9A3;
            }
        }

        &.invalid {
            color: #5E6C84;
            i.material-icons.circle {
                display: block;
                color: #5E6C84;
            }

            span {
                color: white;
            }
        }
    }
}

#edit-user-form {
    .collapse-dropdown-arrow {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox=%278 0 14 24%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill=%27%23344563%27 d=%27M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z%27/%3E%3C/svg%3E");
        width: 26px;
        height: 26px;
        background-position: center;
        background-size: 26px;
    }

    #dialogErrors {
        .alert.alert-danger {
            background-color: transparent;
            padding: 0;
            margin: 0;
            border: none;
            color: initial;
        }
    }
}