#sample-plans-container {
  .test-plan-container {
    a:not(.btn) {
      &:hover, &.active {
        border-color: $dark-blue;
        text-decoration: none;
      }

      &:focus {
        border-color: $black;
      }
    }

    .level-header {
      img {
        height: 120px;
      }
    }

    .level-description, .test-plan-thumbnail {
      box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
    }

    .test-plan-thumbnail {
      @extend .p-5;
      font-size: 50px;
    }

    .level-description {
      .card-body {
        div.mb-5:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}