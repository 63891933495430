.support-card {
  box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);

  a, a:hover, a * {
    color: $black;
    text-decoration: none;

    .card-text {
      text-decoration: none;
    }
  }

  ul li a:hover {
    text-decoration: underline;
  }

  a:hover {
    .card-title {
      text-decoration: underline;
    }
  }

  .card-title {
    color: $black;
  }

  .support-card-header {
    color: $black;

    .support-header-image,
    a .support-header-image {
      i.material-icons {
        font-size: 88px;
        color: $purple;
      }
    }
  }
}