@import "shared";

// $primary-font-regular: 'CeraPro-Regular', sans-serif;
// $primary-font-medium: 'CeraPro-Medium', sans-serif;
// $primary-font-bold: 'CeraPro-Bold', sans-serif;
// $secondary-font-regular: 'HarmoniaSansStd-Regular', sans-serif;
// $secondary-font-bold: 'HarmoniaSansStd-Bold', sans-serif;

$primary-font-regular: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$primary-font-medium: 'Segoe UI Medium', Tahoma, Geneva, Verdana, sans-serif;
$primary-font-bold: 'Segoe UI Bold', Tahoma, Geneva, Verdana, sans-serif;
$secondary-font-regular: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$secondary-font-bold: 'Segoe UI Bold', Tahoma, Geneva, Verdana, sans-serif;

@import "homepage/error";
@import "homepage/form-confirmation";
@import "homepage/form-page";
@import "homepage/home";
@import "homepage/login";
@import "homepage/network_test";
@import "homepage/shared";

@import "homepage/content/about";
@import "homepage/content/blog";
@import "homepage/content/careers";
@import "homepage/content/case-studies";
@import "homepage/content/certification";
@import "homepage/content/data-processing";
@import "homepage/content/faq";
@import "homepage/content/features";
@import "homepage/content/glossary";
@import "homepage/content/licensing";
@import "homepage/content/privacy-policy";
@import "homepage/content/sample-plans";
@import "homepage/content/support";
@import "homepage/content/terms-of-service";
@import "homepage/content/testimonials";

@import "homepage/_recent_updates";

@import "hexawise/_password_validation";

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-family: $primary-font-bold;
  font-size: 44px;
  font-style: normal;
  font-weight: normal;
  line-height: 61px;
}

h2 {
  font-family: $primary-font-bold;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
  line-height: 57px;
}

h3 {
  font-family: $primary-font-bold;
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  line-height: 52px;
}

h4 {
  font-family: $primary-font-bold;
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 48px;
}

h5 {
  font-family: $primary-font-bold;
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 44px;
}

h6 {
  font-family: $primary-font-medium;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 38px;
}

large {
  font-family: $secondary-font-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;

  a {
    font-size: 18px;
  }
}

p, a, button, li {
  font-family: $secondary-font-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 27px;
}

b {
  color: $black;
}

b, b > a {
  font-family: $secondary-font-bold;
  font-style: normal;
  font-weight: normal;

  a {
    font-size: 14px;
    line-height: 24px;
  }
}

label {
  color: #777777;
  font-family: $secondary-font-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

small {
  color: #777777;
  font-family: $secondary-font-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;

  a {
    font-size: 14px;
  }

  b {
    color: $text-white;
    font-family: $secondary-font-bold;
    font-style: normal;
    font-weight: normal;
  }
}

html {
  position: relative;
  min-height: 100%;

  body {
    margin-bottom: 195px;

    .navbar {
      .navbar-brand {
        img {
          height: 28px;
        }
      }
    }

    #top-navigation {
      @extend .container;
      @extend .navbar;
      @extend .navbar-expand-lg;
      @extend .navbar-light;
      @extend .bg-white;
      @extend .justify-content-start;
      @extend .justify-content-lg-center;

      a#header-logo {
        @extend .navbar-brand;
        @extend .d-inline;

        img {
          height: 28px;
        }
      }

      button#hamburger-menu {
        @extend .navbar-toggler;
        @extend .ml-auto;
      }

      div#navbarSupportedContent {
        @extend .navbar-collapse;
        @extend .collapse;
        @extend .w-100;
        @extend .justify-content-between;
        @extend .align-items-center;

        div#content-links {
          @extend .navbar-nav;
          @extend .mx-auto;
          @extend .text-lg-center;

          #login-link {
            @extend .nav-item;
            @extend .nav-link;
            @extend .d-lg-none;
          }
        }

        div#account-links {
          @extend .nav;
          @extend .navbar-nav;
          @extend .flex-row;
          @extend .justify-content-center;
          @extend .flex-nowrap;

          a#login-link {
            @extend .nav-item;
            @extend .nav-link;
            @extend .d-none;
            @extend .d-lg-block;
            @extend .mx-md-1;
            @extend .mx-xl-3;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      .container {
        max-width: 1140px;
      }
    }

    @include media-breakpoint-down(md) {
      .nav-item, .dropdown > a {
        font-weight: bold;
      }
    }

    > div {
      margin: 50px auto;
    }

    a {
      color: $purple;
    }

    ellipse {
      fill: $purple;
      filter: drop-shadow(0px 6px 20px rgba(119, 91, 194, 0.2));

      &.separator {
        fill: $dark-gray;
      }
    }

    input {
      font-family: $secondary-font-regular;
    }

    .hexawise-container {
      max-width: 1280px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 132px;
      line-height: 60px;
      color: $black;
    
      &.minimal {
        width: 100%;
      }
  
      a {
        color: black;
      }
  
      #hexawise-info {
        margin-top: 8px !important;
        color: #777777;

        label {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        &, div, div label {
          height: 16px;
          line-height: 16px;
        }
      }
    }
  }
}

// Adding this generic reset of some input controls to avoid
// overriding the @atlassian/aui styling of such items
input[type=checkbox],
input[type=radio] {
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important
}