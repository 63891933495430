#features-header {
  height: 336px;

  @include media-breakpoint-only(xs) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .container {
    position: relative;
    height: 100%;

    > h3, > div {
      @extend %animation-element-container;
    }

    h3 {
      animation: slideAndFadeIn 0.3s linear 1s forwards;
    }

    .subheader-container {
      animation: slideAndFadeIn 0.3s linear 1.3s forwards;
    }

    .mt-4 {
      animation: fadeIn 0.3s linear 1.6s forwards;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      background: radial-gradient(505.00px at 50% 50%, #775BC2 0%, rgba(119, 91, 194, 0) 100%), url('../images/new_site/backgrounds/circuits.png') no-repeat center;
      background-size: 95%;
      animation: fadeIn 0.5s linear 0.5s forwards;

      @include media-breakpoint-down(md) {
        background: radial-gradient(505.00px at 50% 50%, #775BC2 20%, rgba(119, 91, 194, 0) 100%), url('../images/new_site/backgrounds/circuits.png') no-repeat center;
      }

      @include media-breakpoint-only(xs) {
        background-size: 130%;
      }
    }
  }
}

#features-container {
  #optimization {
    margin-top: -4rem;
  }

  .feature-section {
    @extend .mx-auto;
    @extend .pb-2;
    max-width: 1240px;
    padding-top: 5rem;
    margin-top: -5rem;
    -webkit-background-clip: content-box;
    background-clip: content-box;

    .feature-image {
      margin-bottom: 2.5rem;
      img {
        width: 100%;
        box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
      }
    }
  }
}