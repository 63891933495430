@import '../shared';

#blog-header {
  background: radial-gradient(216.11px at 50% 50%, rgba(119, 91, 194, 0.86) 0%, #775BC2 100%);
}

#blog-container {
  @extend .px-2;
  @extend .mx-auto;

  max-width: 905px;

  .row {
    border-bottom: 2px solid $light-gray;
  }

  .search-toggle {
    color: $gray;
    font-weight: bolder;
    text-decoration: none;
  }

  #category-search-display {
    #category-dropdown {
      text-decoration: none;
    }
  }

  .dropdown {
    #category-dropdown {
      color: black;
    }

    .dropdown-menu {
      max-height: 300px;
      overflow-y: auto;
    }
  }

  #blog-search {
    @extend .text-left;

    font-family: $secondary-font-regular;
    font-weight: bolder;

    border: 0px;

    &:focus {
      border: 0px;
    }

    &::placeholder {
      color: $gray;
    }
  }

  .blog-title a {
    font-family: "CeraPro-Medium", sans-serif;
    font-size: 24px;
    color: black;
    text-decoration: none;
  }

  .blog-thumbnail {
    height: 335px;

    .carousel-caption {
      bottom: 45%;
      color: white;

      &.row {
        border-bottom: none;
      }
    }
    img {
      @extend %image-shadow;
      width: 100%;
    }
  }

  .blog-preview {
    p, iframe, img {
      width: 100%;
    }

    &.quote, blockquote {
      margin-left: -1rem;
      padding-left: 1rem;

      color: $dark-gray;
      font-style: italic;
      border-left: 2px solid $dark-gray;

      + .quote, + blockquote {
        margin-top: -1rem;
      }
    }
  }

  #pagination-container {
    border-bottom: 0px;
  }
}

#blog-post-container {
  #blog-post-content-container {
    max-width: 668px;

    .blog-thumbnail {
      .carousel-caption {
        top: 10px;
        color: white;
      }
      img {
        @extend %image-shadow;
        width: 100%;
      }
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 18px;
    }

    span.badge {
      a {
        color: $white;
      }
    }

    img {
      width: 100%;
    }

    p {
      width: 100%;

      &.quote, blockquote {
        margin-left: -1rem;
        padding-left: 1rem;

        color: $dark-gray;
        font-style: italic;
        border-left: 2px solid $dark-gray;

        + .quote, + blockquote {
          margin-top: -1rem;
        }
      }
    }

    blockquote {
      margin-left: -1rem;
      padding-left: 1rem;

      color: $dark-gray;
      font-style: italic;
      border-left: 2px solid $dark-gray;

      + blockquote {
        margin-top: -1rem;
      }
    }

    .badge-pill {
      @extend .ml-3;
      @extend .mt-3;
      @extend .px-3;

      font-family: $secondary-font-regular;
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
    }
  }

  #blog-related-container {
    img {
      width: 100%;
      box-shadow: 0px 8px 35px rgba(51, 51, 51, 0.07);
    }

    a {
      @extend .h6;

      font-size: 24px;
      font-style: normal;
      font-weight: normal;
      line-height: 38px;

      img {
        @extend %image-shadow;
      }
    }
  }
}